import {
  Button,
  CloseButton,
  Flex,
  Input,
  Spacer,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useStore } from "../../state";

const schema = z.object({
  email: z.string().email(),
});

interface ISignUp {
  onClose?: () => void;
}

// TODO: Correctly type
type Data = any;

export const SignUp: React.FC<ISignUp> = ({ onClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: zodResolver(schema) });
  const [isSubscribing, setIsSubscribing] = React.useState(false);
  const [isSubscribed, setSubscribed] = useStore((state) => [
    state.isSubscribed,
    state.setSubscribed,
  ]);
  const toast = useToast();

  const onSubmit = async (data: Data) => {
    try {
      setIsSubscribing(true);
      await axios.post("/api/subscribe", data);
      setSubscribed();
      toast({
        title: "Subscribed",
        position: "top-right",
        description: "You have been subscribed to the newsletter.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      if (onClose) {
        onClose();
      }
    } catch (e) {
      console.error(e);
      toast({
        title: "Subscription failed",
        position: "top-right",
        description: "There was a problem subscribing. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsSubscribing(false);
    }
  };

  return (
    <VStack
      spacing={{
        base: 4,
        md: 6,
      }}
      alignItems="flex-start"
      w="full"
    >
      {onClose && (
        <Flex w="full">
          <Spacer />
          <CloseButton onClick={onClose} />
        </Flex>
      )}
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <Stack
          direction={{
            base: "column",
            md: "row",
          }}
          alignItems="center"
          justifyContent="center"
          spacing={[0, 2]}
          w="full"
        >
          <Input
            fontFamily="heading"
            fontSize={{
              base: "sm",
              md: "lg",
            }}
            letterSpacing={1.5}
            placeholder="hello@coolperson.dev"
            isInvalid={Boolean(errors.email)}
            isDisabled={isSubscribed || isSubscribing}
            {...register("email", { required: true })}
          />
          <Button
            type="submit"
            fontFamily="heading"
            fontSize={{
              base: "sm",
              md: "lg",
            }}
            w={{
              base: "full",
              md: "sm",
            }}
            isLoading={isSubscribing}
            isDisabled={isSubscribed}
            loadingText="Subscribbling..."
          >
            {isSubscribed ? "Subscribed" : "Subscribe"}
          </Button>
        </Stack>
      </form>
      {errors.email && (
        <Text color="red.500">
          A valid email is required to subscribe and hear cool things
        </Text>
      )}
      <Text
        fontSize={{
          base: "xs",
          md: "xl",
        }}
        textAlign="center"
      >
        Subscribe to my personal newsletter to get updates on TheLastWeekIn.Dev
        + more! 🦄
      </Text>
    </VStack>
  );
};
