import {
  Box,
  Flex,
  Heading,
  HStack,
  Link,
  useColorModeValue as mode,
  Tag,
  Text,
  Wrap,
} from "@chakra-ui/react";
import { BsClockFill } from "@react-icons/all-files/bs/BsClockFill";
import dayjs from "dayjs";
import * as React from "react";
// import { logEvent } from "utils";

interface BlogProps {
  title: string;
  href: string;
  date: string;
  description: string;
  readingTime: string;
  // should be type of NextLink
  LinkComponent?: any;
  author?: {
    name: string;
    href?: string;
  };
  tags?: string[];
  blurHash?: string;
  onClick?: () => void;
}

/**
 * Standard blog card item component used for
 * blogs lists on the TLWI blogs.
 */
export const BlogCard = (props: BlogProps) => {
  const {
    title,
    href,
    tags,
    description,
    author,
    date,
    readingTime,
    onClick,
    LinkComponent = Link,
  } = props;

  const iconColor = mode("blue.600", "blue.200");

  return (
    <Flex direction="column" h="full">
      <Flex direction="column" px={{ sm: "2" }} py="5" h="full">
        <Heading as="h3" size="lg" color="blue.600" mb="2" lineHeight="base">
          <Link
            href={href}
            color={iconColor}
            as={LinkComponent}
            onClick={() => {
              onClick?.();
              // logEvent("Blog post link clicked", {
              //   title,
              //   href,
              //   tags,
              // });
            }}
          >
            {title}
          </Link>
        </Heading>
        {tags && tags.length > 0 && (
          <Wrap mb={4} shouldWrapChildren>
            {tags.map((tag) => (
              <Flex key={tag} justifyContent="center">
                <Tag fontSize="xs">{tag}</Tag>
              </Flex>
            ))}
          </Wrap>
        )}
        <Text mb={4}>{dayjs(date).format("DD MMMM YYYY").toString()}</Text>
        <Text
          lineHeight="tall"
          noOfLines={3}
          mb={4}
          color={mode("gray.600", "gray.400")}
        >
          {description}
        </Text>
        <HStack spacing={1} mb={4}>
          <Box as={BsClockFill} display="inline-block" me="2" opacity={0.4} />
          <Text>{readingTime}</Text>
        </HStack>
        <Flex
          align="baseline"
          justify="space-between"
          fontSize="sm"
          color={mode("gray.600", "gray.400")}
        >
          {author && (
            <Text>
              By{" "}
              <Box as="a" textDecor="underline" href={author.href}>
                {author.name}
              </Box>
            </Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
