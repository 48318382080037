import create from "zustand";

interface State {
  readableFont: boolean;
  isSubscribed: boolean;
  setSubscribed: () => void;
  toggleReadableFont: () => void;
}

export const useStore = create<State>((set) => ({
  readableFont: false,
  isSubscribed: false,
  setSubscribed: () => set({ isSubscribed: true }),
  toggleReadableFont: () =>
    set((state) => ({ readableFont: state.readableFont ? false : true })),
}));
