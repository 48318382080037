import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/alert";
import { useColorModeValue } from "@chakra-ui/color-mode";
import {
  Box,
  Divider,
  Flex,
  HStack,
  Link,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/layout";
import { FaRegCopyright } from "@react-icons/all-files/fa/FaRegCopyright";
import Head from "next/head";
import Image from "next/image";
import NextLink from "next/link";
import { useRouter } from "next/router";
import React from "react";

type Props = {
  alertTitle?: string;
  alertDescription?: string;
  children?: React.ReactNode;
  title?: string;
  site?: string;
};

function getSiteName(site: string) {
  switch (site) {
    case "rust":
      return "More Rust";
    case "typescript":
      return "More TypeScript";
    case "weekly":
      return "More Weekly";
    case "python":
      return "More Python";
    case "golang":
      return "More Golang";
    default:
      return "More";
  }
}

function LayoutLinks({ site }: { site?: string }) {
  const { pathname } = useRouter();

  return (
    <>
      <Link
        as={NextLink}
        href="/"
        fontWeight="bold"
        _hover={{
          color: "pink.400",
          textDecoration: "underline",
        }}
      >
        {pathname.startsWith("/_sites") ? "TLWI.dev" : "Home"}
      </Link>
      {site && (
        <Link
          as={NextLink}
          href={`/${site}`}
          fontWeight="bold"
          _hover={{
            color: "pink.400",
            textDecoration: "underline",
          }}
        >
          {getSiteName(site)}
        </Link>
      )}
      <Link
        as={NextLink}
        href="/about"
        fontWeight="bold"
        _hover={{
          color: "pink.400",
          textDecoration: "underline",
        }}
        sx={{
          display: ["none", "block", "block", "block"],
        }}
      >
        Why TWLI.dev?
      </Link>
    </>
  );
}

export const Layout = ({
  alertTitle,
  alertDescription,
  children,
  site,
  title = "This is the default title",
}: Props) => {
  const footerColor = useColorModeValue("blue.600", "blue.200");

  const hasAlert = alertTitle && alertDescription;

  return (
    <div>
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <header>
        {hasAlert && (
          <Alert
            status="info"
            justifyContent="center"
            textAlign="center"
            flexDir={["column", "row"]}
          >
            <AlertIcon />
            {alertTitle && <AlertTitle mr={2}>{alertTitle}</AlertTitle>}
            {alertDescription && (
              <AlertDescription>{alertDescription}</AlertDescription>
            )}
          </Alert>
        )}
        <nav>
          <Stack
            px={{
              base: 4,
              md: 8,
            }}
            py={{
              base: 8,
              md: 8,
            }}
            w="full"
            alignItems="center"
            direction={"row"}
          >
            <HStack spacing={[4, 8]} w={["full", "initial"]}>
              <Image
                alt="TLWI.dev logo"
                src="/assets/tlwi-dev-new-logo.svg"
                priority
                width={60}
                height={60}
              />
              <LayoutLinks site={site} />
            </HStack>
            {/* <Spacer />
            <Box>
              <FormControl display="flex" alignItems="center">
                <FormLabel fontSize="sm" htmlFor="more-readable-text" mb={0}>
                  More readable text?
                </FormLabel>
                <Switch
                  id="more-readable-text"
                  isChecked={readableFont}
                  onChange={toggleReadableFont}
                />
              </FormControl>
            </Box> */}
          </Stack>
        </nav>
      </header>
      <Box px={4} minH="70vh">
        {/* @ts-ignore: to fix */}
        {children}
      </Box>
      <footer>
        <Divider />
        <Flex
          px={8}
          py={4}
          flexDir={["column", "row"]}
          textAlign={["center", "center"]}
        >
          <Box>
            <HStack>
              <Text fontWeight="semibold">
                Built by{" "}
                <Link
                  as={NextLink}
                  href="https://dennisokeeffe.com"
                  fontWeight="bold"
                  color={footerColor}
                  target="_blank"
                  _hover={{ color: footerColor, textDecoration: "underline" }}
                >
                  {`Dennis O'Keeffe`}
                </Link>
              </Text>
            </HStack>
          </Box>
          <Spacer />
          <Box>
            <HStack>
              <FaRegCopyright />
              <Text fontWeight="semibold">{`TLWI.dev, ${new Date().getFullYear()}`}</Text>
            </HStack>
          </Box>
        </Flex>
      </footer>
    </div>
  );
};
