import { Box, useColorModeValue, useToast } from "@chakra-ui/react";
import { useEffect } from "react";

import { SignUp } from "./SignUp";

export const useSignUpToast = () => {
  const toast = useToast();
  const bgColor = useColorModeValue("white", "gray.700");

  useEffect(() => {
    const showPopup = localStorage.getItem("no-tlwi-popup");
    let timer: number | undefined;

    if (!showPopup) {
      timer = window?.setTimeout(() => {
        toast({
          position: "bottom-right",
          /* eslint-disable-next-line react/display-name */
          render: ({ onClose }) => (
            <Box
              maxW={{
                base: "sm",
                md: "lg",
              }}
              bgColor={bgColor}
              rounded={4}
              p={{
                base: 4,
                md: 8,
              }}
              shadow="xl"
            >
              <SignUp
                onClose={() => {
                  onClose();
                  localStorage.setItem("no-tlwi-popup", "false");
                }}
              />
            </Box>
          ),
          duration: null,
          isClosable: true,
        });
      }, 20000);
    }

    return () => {
      if (timer) {
        window.clearTimeout(timer);
      }
    };
  }, [toast, bgColor]);
};
