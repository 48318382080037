import { default as NextHead } from "next/head";

type HeadProps = {
  site: string;
  title?: string;
  description?: string;
  urlSuffix?: string;
  image?: string;
};

function getTitle(site: HeadProps["site"]) {
  switch (site) {
    case "rust":
      return "Rust";
    case "golang":
      return "Golang";
    case "typescript":
      return "TypeScript";
    case "python":
      return "Python";
    case "weekly":
      return "Developer Weekly";
  }
}

function getDescription(site: HeadProps["site"]) {
  switch (site) {
    case "rust":
      return "A crabby little Rust blog.";
    case "golang":
      return "Curated Golang content.";
    case "typescript":
      return "A weekly TypeScript newsletter.";
    case "python":
      return "An endearing little Python newsletter.";
    case "weekly":
      return "A petite curation of developer news.";
  }
}

function ogTitle(site: HeadProps["site"]) {
  switch (site) {
    case "rust":
      return "TLWI Rust";
    case "golang":
      return "TLWI Golang";
    case "typescript":
      return "TLWI TypeScript";
    case "python":
      return "TLWI Python";
    case "weekly":
      return "TLWI Developer Weekly";
  }
}

export function Head({
  site,
  title,
  description,
  image,
  urlSuffix = "",
}: HeadProps) {
  return (
    <NextHead>
      <title>{title || getTitle(site)}</title>
      <meta name="description" content={description || getDescription(site)} />
      <link
        rel="canonical"
        href={`https://www.thelastweekin.dev/${site}${urlSuffix}`}
      />
      <meta property="og:title" content={title || ogTitle(site)} key="title" />
      <meta
        property="og:url"
        content={`https://www.thelastweekin.dev/${site}${urlSuffix}`}
        key="url"
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:description"
        content={description || getDescription(site)}
      />
      <meta name="twitter:title" content={title || ogTitle(site)} />
      <meta
        property="twitter:description"
        content={description || getDescription(site)}
      />
      <meta
        property="twitter:url"
        content={`https://www.thelastweekin.dev/${site}${urlSuffix}`}
        key="twitter-url"
      />
      {image && <meta property="twitter:image" content={image} />}
    </NextHead>
  );
}
